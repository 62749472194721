// src/components/common/Header.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/Header.css"; // Import the CSS file
import logo from "../../assets/images/logo3.svg"; // Import the logo image
import menuIcon from "../../assets/icons/menu_icon.svg"; // Import the menu icon

const Header = ({ companyName }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`header ${isMenuOpen ? "menu-open" : ""}`}>
      <div className="logo">
        <img src={logo} alt="HVAC Logo" />
        <span className="company-title">{companyName}</span>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        <img src={menuIcon} alt="Menu Icon" />
      </div>
      <nav className={`nav-links ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link to="/" onClick={() => setIsMenuOpen(false)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about-us" onClick={() => setIsMenuOpen(false)}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/request-service" onClick={() => setIsMenuOpen(false)}>
              Request Service
            </Link>
          </li>
          <li>
            <Link to="/control-panel" onClick={() => setIsMenuOpen(false)}>
              Control Panel
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
