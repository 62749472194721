import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import editIcon from "../../assets/icons/edit_icon.svg";
import deleteIcon from "../../assets/icons/delete_icon.svg";
import "../../styles/SlideShowManagement.css";

const SlideShowManagement = () => {
  const [imageURL, setImageURL] = useState("");
  const [description, setDescription] = useState("");
  const [slides, setSlides] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(null);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const res = await axiosInstance.get("/slides");
        setSlides(res.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSlides();
  }, []);

  const handleImageURLChange = (e) => {
    setImageURL(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { imageURL, description, date: new Date() };
    console.log("Submitting Form Data:", formData); // Log the form data

    try {
      const response = await axiosInstance.post("/slides", formData);
      console.log("Submit Response:", response);
      // Fetch the updated slides after adding a new one
      const res = await axiosInstance.get("/slides");
      setSlides(res.data);
      // Clear form or show success message
      setImageURL("");
      setDescription("");
    } catch (err) {
      console.error("Submit Error:", err.response ? err.response.data : err);
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/slides/${currentSlide._id}`);
      // Fetch the updated slides after deletion
      const res = await axiosInstance.get("/slides");
      setSlides(res.data);
      setShowDeleteModal(false);
    } catch (err) {
      console.error("Delete Error:", err);
    }
  };

  const handleEdit = async () => {
    try {
      await axiosInstance.put(`/slides/${currentSlide._id}`, {
        imageURL: currentSlide.imageURL,
        description: currentSlide.description,
      });
      // Fetch the updated slides after editing
      const res = await axiosInstance.get("/slides");
      setSlides(res.data);
      setShowEditModal(false);
    } catch (err) {
      console.error("Edit Error:", err);
    }
  };

  return (
    <div className="slide-show-management">
      <h3>Manage Slide Show</h3>
      <form onSubmit={handleSubmit}>
        <label>
          Image URL:
          <input
            type="text"
            value={imageURL}
            onChange={handleImageURLChange}
            required
          />
        </label>
        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </label>
        <button type="submit">Add Slide</button>
      </form>
      <div className="slides-list">
        <h4>Slides</h4>
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Description</th>
              <th>Date Posted</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {slides.map((slide) => (
              <tr key={slide._id}>
                <td>
                  <img
                    src={slide.imageURL}
                    alt={slide.description}
                    width="50"
                  />
                </td>
                <td className="description">{slide.description}</td>
                <td>{new Date(slide.createdAt).toLocaleDateString()}</td>
                <td>
                  <button
                    onClick={() => {
                      setCurrentSlide(slide);
                      setShowEditModal(true);
                    }}
                  >
                    <img src={editIcon} alt="Edit" />
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      setCurrentSlide(slide);
                      setShowDeleteModal(true);
                    }}
                  >
                    <img src={deleteIcon} alt="Delete" />
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>Are you sure you want to delete this slide?</h4>
            <img
              src={currentSlide.imageURL}
              alt={currentSlide.description}
              width="200"
            />
            <p>{currentSlide.description}</p>
            <button onClick={handleDelete}>Delete</button>
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>Edit Slide Description</h4>
            <label>
              New description:
              <textarea
                value={currentSlide.description}
                onChange={(e) =>
                  setCurrentSlide({
                    ...currentSlide,
                    description: e.target.value,
                  })
                }
              ></textarea>
            </label>
            <button onClick={handleEdit}>Save</button>
            <button onClick={() => setShowEditModal(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlideShowManagement;
